<script>
import TrustedDevice from "./TrustedDevice.vue";

export default {
  name: "TrustedDeviceManagement",
  components: {TrustedDevice},
  data: () => ({
    devices: []
  }),
  async mounted() {
    await this.fetchDevices()
  },
  methods: {
    async fetchDevices(){
      this.$vs.loading();
      try {
        const {data} = await axios.get("api/auth/devices")
        this.devices = data
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>

<template>
  <vx-card title="Dispositivos de confianza." class="mt-base">
    <div class="flex flex-col gap-4">
      <TrustedDevice
          @on-device-deleted="fetchDevices"
          :device="device" v-for="device in devices"/>
    </div>
  </vx-card>
</template>
<script>
export default {
  name: "TrustedDevice",
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCurrentDevice() {
      return this.device.device_id === this.$cookies.get("device_id");
    }
  },
  methods: {
    async closeSessionHandler() {
      this.$vs.loading();
      try {
        await axios.post('/api/auth/devices/close-session', {device_id: this.device.device_id})
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: 'No se pudo cerrar la sesión',
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close();
      }
    },
    confirmDeletionHandler() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar dispositivo',
        text: '¿Estás seguro de que deseas eliminar este dispositivo de confianza?',
        acceptText: 'Eliminar',
        accept: this.deleteDeviceHandler
      })
    },
    async deleteDeviceHandler() {
      this.$vs.loading();
      try {
        await axios.delete(`/api/auth/devices/${this.device.device_id}`)
        this.$emit('on-device-deleted')
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: 'No se pudo eliminar el dispositivo',
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="flex gap-4 items-center justify-start">
      <i class="fa text-3xl fa-desktop"></i>
      <div>
        <div>
          <span class="bold">{{ device.os_name }} - {{ device.browser_name }}</span>
          <span v-if="isCurrentDevice" class="text-primary ml-2 italic text-sm">Dispositivo actual</span>
        </div>
        <div>
          {{ device.ip_address }} - {{device.location}}
        </div>
      </div>
    </div>
    <vs-button
        @click="confirmDeletionHandler"
        color="danger"
        size="small"
        class="mt-3 mr-2"
        v-if="!isCurrentDevice"
    >
      Eliminar
    </vs-button>
    <vs-button
        @click="closeSessionHandler"
        color="black"
        size="small"
        class="mt-3"
        v-if="!isCurrentDevice"
    >
      Cerrar sesión
    </vs-button>
  </div>
</template>

<style scoped>

</style>